export function GetUrlParameter(aUrl, aParamKey)
{
  let Index = aUrl.indexOf("?" + aParamKey + "=")
  if (Index < 1) { Index = aUrl.indexOf("&" + aParamKey + "=") }
    
  if (Index > 0)
  {
    aUrl = aUrl.substring(Index + aParamKey.length + 2)

    Index = aUrl.indexOf("&")
    return (Index > 0) ? aUrl.substring(0,Index) : aUrl
  }
  else
  {
    return ""
  }
}

export function GetDelphiDateTimeNow()
{
  return (new Date().getTime()) / (24 * 60 * 60 * 1000) + 25569 
}

export function IsEmptyString(aString)  
{
    return aString === undefined
        || aString === null
        || !aString
}