import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from 'prop-types';
import { jwtDecode } from 'jwt-decode'
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faPlus } from '@fortawesome/free-solid-svg-icons';
import Popup from 'reactjs-popup';
import Cookies from 'js-cookie';

import MainHome from "./Main/MainHome.jsx";
import MainIntegration from "./Main/MainIntegration.jsx";
import MainCallFieldScript from "./Main/MainCallFieldScript.jsx";
import { ConfigurationApiIntegrationDelete } from "../ExternalApi/ConfigurationApi.js"

import { ConfigurationApiCRMScriptsGet, ConfigurationApiIntegrationGet } from "../ExternalApi/ConfigurationApi.js";
import { SetLanguageId, GetLanguageId, GetLanguageCaption, ResetLanguageId, Translate } from "./Translator";
import { IsEmptyString } from "./GeneralFunctions"

import CloudCTIPng from '../Assets/branding_icon_cloudcti.png';
import EnreachPng from '../Assets/branding_icon_enreach.png';
import SignOutPng from '../Assets/SignOut.png';
import UserPng from '../Assets/User.png';
import HomePng from '../Assets/Home.png';
import HomeLightPng from '../Assets/HomeLight.png';
import IntegrationPng from '../Assets/Integration.png';
import IntegrationLightPng from '../Assets/Integration_Light.png';

import "../css/Main.css"

//Subclasses

const Main = (props) => {

  const mHistory = useHistory();

  //debug
  //const mSecurityToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTaWduaW5NZXRob2QiOiJDbG91ZENUSSIsIlNpZ25pblVzZXIiOiJhbmRyZS52ZW5lbWFAZ21haWwuY29tIiwiU2lnbmluUGFzc3dvcmQiOiJ5cmxDUnRQMXMyMGoxdHJCSjErU1F1dUFoQlU3aDhrYjVFSUoyTDRlRGRJVzgrSXBFaGNmMGhVU0RHVWEvQitGeHN4RkViV3FXUFlJRHJkeTZhUzZtV1gyemUraXFMcHZJOFRmL1RYeDRuMWNpMnJPZi9VTjFiMGlQeHJwdUprYyIsIlNpZ25pbkFwcGxpY2F0aW9uIjoiUmVjb2duaXRpb25Db25maWd1cmF0aW9uVG9vbCIsIlNpZ25pblByb2R1Y3QiOiJDbG91ZENUSSIsIlRlbmFudEd1aWQiOiJUZW5hbnRfNjE4YmNhYmItNDZkYS00MmViLWFmOTQtOWQ4NWMyNzY3ZTM0IiwiVGVuYW50TmFtZSI6IktleWxpbmsiLCJVc2VyR3VpZCI6IlRlbmFudFVzZXJfMTI1ZWMzODctYzYyOS00MmNjLWJjMmItZTU2MTI1OTMxZjMxIiwiVXNlck5hbWUiOiJBbmRyZSBWZW5lbWEiLCJVc2VySXNBZG1pbiI6IjEiLCJVc2VySXNUZW5hbnRBZG1pbiI6IjEiLCJFbWFpbCI6ImFuZHJlLnZlbmVtYUBnbWFpbC5jb20iLCJMaWNlbmNlTmFtZSI6IkNsb3VkQ1RJIFVzZXIiLCJSZWNvZ25pdGlvbkFuZFNjcmVlbnBvcCI6IjEiLCJSb2xlcyI6IlVzZXIsQWRtaW5pc3RyYXRvciIsImV4cCI6MTczNjkzNTE3OCwiaXNzIjoic2lnbmluLXZhIiwiYXVkIjoiQ2xvdWRDVEkyMDIyIn0.gG7zK2JBXyp1lY3X-hfZzh8cOV8OUIIT8pJlaFbIyCQ";
  const mSecurityToken = Cookies.get("webconfigurator")

  const [mUserName, SetUserName] = useState("")
  const [mTenantName, SetTenantName] = useState("")

  const [mRepainter, SetRepainter] = useState(false)

  const [mPredefinedScripts, SetPredefinedScripts] = useState([])  
  const [mIntegrations, SetIntegrations] = useState([]) 

  const [mIntegrationDelete, SetIntegrationDelete] = useState(false) 

  const [mSelectedIntegrationGuid, SetSelectedIntegrationGuid] = useState(window.sessionStorage.getItem("SelectedIntegrationGuid"));
  const [mSelectedCallfieldScriptIndex, SetSelectedCallfieldScriptIndex] = useState(parseInt(window.sessionStorage.getItem("SelectedCallfieldScriptIndex")));

  useEffect(() => {
    const DecodedToken = jwtDecode(mSecurityToken)
    SetUserName(DecodedToken.UserName)
    SetTenantName(DecodedToken.TenantName)

    if (DecodedToken["OnBehalfOfUserType"] === "enreachcontact")
    {
      localStorage.setItem("signinmethodoverride", "enreachcontact")

      const Platform = DecodedToken["enreachcontact.aud"]
      localStorage.setItem("platformoverride", IsEmptyString(Platform) ? "" : Platform)
    }
   
    ResetLanguageId()
    ConfigurationApiCRMScriptsGet(mSecurityToken).then(result => { SetPredefinedScripts(result) })    
    ConfigurationApiIntegrationGet(mSecurityToken).then(result => { SetIntegrations(result) })

    //Dit zet ik voor de development even uit
    const Interval = setInterval(() => RefreshIntegrations(), 60000); 
    return () => clearInterval(Interval);
  }, []); 

  const LanguageChanged = () => {
    SetRepainter(!mRepainter)
  }

  const RefreshIntegrations = () => {
    console.log("Main.RefreshIntegrations")
    ConfigurationApiIntegrationGet(mSecurityToken).then(result => { SetIntegrations(result) })
  }

  const SelectIntegration = (aIntegrationGuid) => {
    SetSelectedCallfieldScriptIndex(null)
    window.sessionStorage.setItem("SelectedCallfieldScriptIndex", "");

    SetSelectedIntegrationGuid(aIntegrationGuid)
    window.sessionStorage.setItem("SelectedIntegrationGuid", aIntegrationGuid);
  }

  const SelectCallFieldScript = (aScriptIndex) => {
    SetSelectedIntegrationGuid(null)
    window.sessionStorage.setItem("SelectedIntegrationGuid", "");

    SetSelectedCallfieldScriptIndex(aScriptIndex)
    window.sessionStorage.setItem("SelectedCallfieldScriptIndex", aScriptIndex);
  }

  const SignOut = () => {
    Cookies.remove('webconfigurator', { domain: '.cloudcti.nl' })
    mHistory.push("/")
  }  

  const OpenPopupFullscreen = (aUrl) => {
    window.open(aUrl, "_blank", 'popup=yes,titlebar=0,left=' + ((screen.availWidth - 1600)/2)  + ',top=' + ((screen.availHeight - 800)/2)  + ',width=1600,height=800')
  }

  const IntegrationDelete = async () => {
    SetIntegrationDelete(true)
  }

  const RemoveIntegration = async () => {
    SetIntegrationDelete(false)
    ConfigurationApiIntegrationDelete(mSecurityToken, mSelectedIntegrationGuid).then(() => ConfigurationApiIntegrationGet(mSecurityToken)).then((result) => { SetIntegrations(result) }).then(() => SetSelectedIntegrationGuid(""))
  }

  if (!mSecurityToken || !mPredefinedScripts || !mIntegrations)
  {
    return (
      <div className="loading-outer">
        <div className="loading-inner">
          <FontAwesomeIcon icon={faSpinner} spin /> {Translate("Loading...")}
        </div>
      </div>
    );       
  }
  else
  {
    if (localStorage.getItem("SelectedIntegrationGuid") !== "")
    {
      SelectIntegration(localStorage.getItem("SelectedIntegrationGuid"))
      localStorage.setItem("SelectedIntegrationGuid","")
    } 
  
    if ((localStorage.getItem("SelectedCallfieldScript") === "last"))
    {
      SelectCallFieldScript(100)
      localStorage.setItem("SelectedCallfieldScript", "")
    }

    //All regular integrations
    const Integrations = mIntegrations.filter(integration => integration.IntegrationGuid !== "DefaultScripts")

    //All callfield scripts
    const DefaultScriptsIntegrations = mIntegrations.filter(integration => integration.IntegrationGuid === "DefaultScripts")
    const CallFieldScripts = DefaultScriptsIntegrations && (DefaultScriptsIntegrations.length === 1) ? (DefaultScriptsIntegrations[0].Scripts ?? []) : []
 
    const SelectedIntegration = Integrations.find(integration => integration.IntegrationGuid === mSelectedIntegrationGuid)
    const SelectedCallfieldScript = mSelectedCallfieldScriptIndex === 100 ? CallFieldScripts[CallFieldScripts.length - 1] : (CallFieldScripts.length > mSelectedCallfieldScriptIndex) && (mSelectedCallfieldScriptIndex > -1) ? CallFieldScripts[mSelectedCallfieldScriptIndex] : null
   
    const BrandingId = sessionStorage.getItem("BrandingId")

    return (   

        <table className="main" cellPadding={0}>        
        <tbody>
        <tr height="16" className="pagetop">
            <td width="*"/>
            <td width="600"/>
            <td width="600"/>
            <td width="*"/>
        </tr> 
        <tr className="pagetop">
            <td colSpan="2">&nbsp;&nbsp;&nbsp;&nbsp;<img src={BrandingId === "enreach" ? EnreachPng : CloudCTIPng}/> &nbsp; Webconfigurator</td>
            <td colSpan="2" width="*" align="right" valign="top">
              <span style={{ marginRight: '16px'}}><Button variant="outline-light" className={BrandingId === "enreach" ? "brandbuttonsignout" : "brandbuttonsignoutkeeporiginalstyle"} onClick={() => SignOut()}><img src={SignOutPng} height="20" style={{marginTop: '-3px'}}/> &nbsp;&nbsp;{Translate("Sign out")}</Button></span>
            </td>
        </tr>  
        <tr height="16" className="pagetop"><td colSpan="4"/></tr> 
        <tr height="10" />
        <tr>
          <td/>
          <td colSpan="2" valign="top">
            <br/><br/>

            <table width="100%" height="100%" cellPadding="0" cellSpacing="0">
            <tbody>
            <tr>
              <td width="300" valign="top">
                <div className="mainmenu">
                  <table cellPadding={20} width="100%" height="100%">
                  <tbody>
                    <tr>
                      <td valign="top">
                        <table cellPadding={10} width="100%" className={mSelectedIntegrationGuid === "" ? "mainmenuitem surfaceselected" : "mainmenuitem"} onClick={() => SelectIntegration("")}>
                        <tbody>
                          <tr>
                            <td width="30" align="left" valign="center"><img src={BrandingId === "enreach" ? HomePng : (mSelectedIntegrationGuid === "" ? HomePng : HomeLightPng)} className="mainmenuhomeicon" /></td>
                            <td width="*" align="left" valign="center"><div style={{ marginLeft: '-6px', marginBottom: '-6px' }}>Home</div></td>
                          </tr>
                        </tbody>
                        </table>

                        <table width="100%">
                        <tbody>
                          <tr height="12"><td className="surfacedividerbottom"></td></tr>
                          <tr height="12"/>
                        </tbody>
                        </table>

                        <div className="mainmenusubheader" disabled>{Translate("Integrations")}</div>
                        {Integrations.map((integration, index) => (
                          <table key={index} cellPadding={10} width="100%" className={integration.IntegrationGuid === mSelectedIntegrationGuid ? "mainmenuitem surfaceselected" : "mainmenuitem"} onClick={() => SelectIntegration(integration.IntegrationGuid)}>
                          <tbody>
                          <tr>
                            <td width="24" align="left" valign="center">
                              <img src={BrandingId === "enreach" || integration.IntegrationGuid === mSelectedIntegrationGuid ? IntegrationPng : IntegrationLightPng} className={integration.Enabled ? "mainmenuicon" : "mainmenuicon halfopaque"}/>
                            </td>
                            <td width="*" align="left" valign="center" className={integration.Enabled ? "" : "surfacedisabled"}>{integration.ApplicationName}</td>
                          </tr>
                          </tbody>
                          </table>
                        ))}

                        <table width="100%">
                        <tbody>
                          <tr height="12"><td className="surfacedividerbottom"></td></tr>
                          <tr height="12"/>
                        </tbody>
                        </table>

                        <div className="mainmenusubheader mainmenusubitemsurfacedividerbottom">{Translate("Scripts")}</div>
                        {CallFieldScripts.map((script, index) => (
                          <table key={index} cellPadding={10} width="100%" className={((index === mSelectedCallfieldScriptIndex) || ((index === CallFieldScripts.length - 1) && (mSelectedCallfieldScriptIndex === 100))) ? "mainmenuitem surfaceselected" : "mainmenuitem"} onClick={() => SelectCallFieldScript(index)}>
                          <tbody>
                          <tr>
                            <td width="24" align="left" valign="center">
                              <img src={BrandingId === "enreach" || (index === mSelectedCallfieldScriptIndex) || ((index === CallFieldScripts.length - 1) && (mSelectedCallfieldScriptIndex === 100)) ? IntegrationPng : IntegrationLightPng} className={script.Enabled ? "mainmenuicon" : "mainmenuicon halfopaque"} />
                            </td>
                            <td width="*" align="left" valign="center" className={script.Enabled ? "" : "surfacedisabled"}>{script.XmlApplication ? script.XmlApplication : script.Name}</td>
                          </tr>
                          </tbody>
                          </table>
                        ))}

                        <table width="100%">
                        <tbody>
                          <tr height="12"><td className="surfacedividerbottom"></td></tr>
                          <tr height="12"/>
                        </tbody>
                        </table>

                        <Button variant="dark" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} style={{ width: '100%'}} onClick={() => OpenPopupFullscreen("/IntegrationWizard?Action=add&After=&SecurityToken=" + mSecurityToken + "&languageid=" + GetLanguageId())}>{Translate("Add new integration").toUpperCase()}</Button>
                        <Button variant="dark" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} style={{ width: '100%'}} onClick={() => OpenPopupFullscreen("/ScriptWizard?Action=add&After=&SecurityToken=" + mSecurityToken + "&languageid=" + GetLanguageId())}>{Translate("Add new script").toUpperCase()}</Button>
                      </td>
                      </tr>
                  </tbody>
                  </table>
                </div>
              </td>
              <td width="50"></td>
              <td width="850" valign="top">
                {SelectedIntegration ? <MainIntegration SecurityToken={mSecurityToken} UserName={mUserName} Integration={SelectedIntegration} History={mHistory} OnOpenPopupFullscreen={OpenPopupFullscreen} OnIntegrationDelete={IntegrationDelete} OnIntegrationsChanged={SetIntegrations} OnIntegrationsRefresh={RefreshIntegrations}/> 
                  : SelectedCallfieldScript ? <MainCallFieldScript SecurityToken={mSecurityToken} Scripts={CallFieldScripts} SelectedIndex={mSelectedCallfieldScriptIndex === 100 ? CallFieldScripts.length - 1 : mSelectedCallfieldScriptIndex} PredefinedScripts={mPredefinedScripts} OnOpenPopupFullscreen={OpenPopupFullscreen} OnIntegrationsChanged={SetIntegrations} /> 
                    : <MainHome TenantName={mTenantName} UserName={mUserName} OnLanguageChanged={LanguageChanged} OnSignOut={SignOut}/>}  
              </td>
            </tr>
            </tbody>
            </table>
            
            <br/><br/>

            <div align="center" valign="bottom" className="copyrightbottom">© 2024 CloudCTI</div>

          </td>  
          <td/>
        </tr>
        </tbody>
        <Modal animation={false} show={mIntegrationDelete} onHide={() => SetIntegrationDelete(false)} centered={true}>
            <Modal.Header>
                <Modal.Title><span className="wizardstepheader" align="center">{Translate("Do you really want to remove this integration?")}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body align="center">
                <Button variant="outline-dark" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} onClick={() => SetIntegrationDelete(false)} style={{ padding: '12px 50px' }}>{Translate("No")}</Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="dark" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} onClick={() => RemoveIntegration()} style={{ padding: '12px 50px' }}>{Translate("Yes")}</Button>
            </Modal.Body>
        </Modal>
        </table>
    );
  }  
}

export default Main;